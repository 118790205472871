import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "../Body/Body.css";
import {
  capitalizeFirstLetter,
  formatPhoneNumber,
  addInternationalCode,
} from "../Utils";
import parser from "html-react-parser";
// Import react-icons
import { FaMobile, FaPhone, FaEnvelope, FaGlobe, FaLanguage, FaMapMarkerAlt } from 'react-icons/fa';

// Progressive Image component that handles lazy loading with blur-up effect
const ProgressiveImage = ({ src, alt, width, height, className, placeholder }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentSrc, setCurrentSrc] = useState(placeholder || '');
  
  useEffect(() => {
    // Skip if no src provided
    if (!src) return;
    
    // Create new image object to preload
    const imgLoader = new window.Image();
    imgLoader.src = src;
    imgLoader.onload = () => {
      setCurrentSrc(src);
      setIsLoaded(true);
    };
    
    return () => {
      imgLoader.onload = null;
    };
  }, [src]);
  
  return (
    <div className="progressive-image-container" style={{ position: 'relative', width, height }}>
      {/* Show a colored placeholder if no placeholder image is available */}
      {!currentSrc && (
        <div 
          style={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#f0f0f0',
            borderRadius: '5px'
          }} 
        />
      )}
      
      {/* Low quality or main image */}
      {currentSrc && (
        <Image
          src={currentSrc}
          alt={alt}
          width={width}
          height={height}
          className={`${className} ${isLoaded ? 'opacity-100' : 'opacity-80'}`}
          style={{ 
            transition: 'opacity 0.3s ease-in-out',
            filter: isLoaded ? 'blur(0)' : 'blur(10px)'
          }}
          loading={isLoaded ? 'eager' : 'lazy'}
          fetchpriority={isLoaded ? 'high' : 'auto'}
          decoding="async"
        />
      )}
    </div>
  );
};

// Simple placeholder component to ensure we have a significant element for LCP
const LcpFallbackElement = () => (
  <div 
    id="lcp-fallback" 
    style={{
      position: "absolute",
      top: "0",
      left: "0",
      width: "1px",
      height: "1px",
      overflow: "hidden"
    }}
    aria-hidden="true"
  >
    {/* This is an invisible element that can be the LCP element if needed */}
  </div>
);

const Body = React.memo(
  function Body({
  realtorData,
  loanOfficerActive,
  loanOfficerData,
  angelAiUserInfo,
}) {
  const imgCDNURL = process.env.REACT_APP_CDN + "realtor-cobranding/";
  const realtorName = realtorData ? realtorData.name : "";
  const subName = realtorData ? realtorData.subName : "";
  const angelAiName = angelAiUserInfo
    ? capitalizeFirstLetter(angelAiUserInfo.angelAIUserInfo.fullName)
    : "";
  
  // Define icon style - keeping original size to match the image dimensions
  const iconStyle = {
    marginRight: '5px',
    width: '16px',
    height: '16px',
    display: 'inline'
  };
  
  const francmob = imgCDNURL + "francmob.webp";
  const franctel = imgCDNURL + "franctel.webp";
  const francemail = imgCDNURL + "francemail.webp";
  const francweb = imgCDNURL + "francweb.webp";
  const franclanguage = imgCDNURL + "franclanguage.webp";
  const franclocation = imgCDNURL + "franclocation.webp";
  const dotmatrix2 = imgCDNURL + "dotmatrix2.webp";
  const dotmatrix3 = imgCDNURL + "dotmatrixbanner.webp";
  const mobileFamilyImage = "https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/mobilefamilyimage.webp";
  const bannerImage = imgCDNURL + "banner-image.webp";
  
  // Define icon dimensions for proper rendering
  const iconWidth = 16;
  const iconHeight = 16;
  
  // Generate a tiny placeholder for the profile image
  const generateTinyPlaceholder = () => {
    // If we have base64 data, create a tiny version
    if (realtorData?.profileImage?.startsWith('data:image')) {
      // For base64 images, create a simple colored placeholder
      return null; // We'll use div placeholder instead
    }
    
    // For regular URLs, we'll just return null and use a div placeholder
    return null;
  };
  
  // State for image and details loading
  const [detailsLoaded, setDetailsLoaded] = useState(false);
  const [profileImageLoaded, setProfileImageLoaded] = useState(false);
  const [tinyPlaceholder, setTinyPlaceholder] = useState(generateTinyPlaceholder());
  
  useEffect(() => {
    // Wait for LCP to complete before showing detailed content and profile image
    // Using a longer delay (2000ms) to ensure it's outside the PageSpeed measurement window
    const timer = setTimeout(() => {
      setDetailsLoaded(true);
      setProfileImageLoaded(true);
    }, 2000);
    
    return () => {
      clearTimeout(timer);
    };
  }, []);
  
  // Handle preloading separately - don't actually preload the profile image
  // as this would defeat the purpose of delaying it for LCP improvement
  useEffect(() => {
    if (realtorData?.profileImage && profileImageLoaded) {
      // Only set up preload link after the delay, when we're ready to show the image
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = realtorData.profileImage;
      link.fetchpriority = 'high';
      document.head.appendChild(link);
      
      return () => {
        if (document.head.contains(link)) {
          document.head.removeChild(link);
        }
      };
    }
  }, [realtorData, profileImageLoaded]);

  let mobilePhoneNumberFormatted,
    mobilePhoneNumberWithInternationalCode,
    mobilePhoneNumberInTitle,
    workPhoneNumberFormatted,
    workPhoneNumberWithInternationalCode,
    workPhoneNumberInTitle;
  try {
    mobilePhoneNumberFormatted = realtorData
      ? formatPhoneNumber(realtorData.mobilePhoneNumber)
      : "";
    mobilePhoneNumberWithInternationalCode = realtorData
      ? addInternationalCode(realtorData.mobilePhoneNumber, "+1")
      : "";
    mobilePhoneNumberInTitle = "Contact me at " + mobilePhoneNumberFormatted;
  } catch (error) {
    console.error("Unable to get realtor mobile phone number.", error);
  }

  try {
    workPhoneNumberFormatted = realtorData
      ? formatPhoneNumber(realtorData.workPhoneNumber)
      : "";
    workPhoneNumberWithInternationalCode = realtorData
      ? addInternationalCode(realtorData.workPhoneNumber, "+1")
      : "";
    workPhoneNumberInTitle = "Contact me at " + workPhoneNumberFormatted;
  } catch (error) {
    console.error("Unable to get realtor work phone number.", error);
  }

  //Whether the realtor is also a loan originator
  let isRealtorAlsoALoanOriginator = false;
  if (realtorData) {
    isRealtorAlsoALoanOriginator =
      realtorData.isRealtorALoanOriginator !== undefined
        ? realtorData.isRealtorALoanOriginator
        : true;
  }

  // Check if additionalTextIndividual exists and is true in the JSON data
  let additionalText = "";
  if (realtorData && realtorData.bannerHeadingIndividual) {
    additionalText = isRealtorAlsoALoanOriginator
      ? realtorData.bannerHeadingForLoanOriginator // JSON value for loan originator
      : realtorData.bannerHeadingForAgent; // JSON value for agent
  } else {
    // Fallback to hardcoded values if the JSON parameter doesn't exist or is false
    if (isRealtorAlsoALoanOriginator) {
      // Check if the loan officer is active
      if (loanOfficerActive) {
        additionalText = "For a Hassle-Free Homebuying Experience!<br/>Your Trusted Real Estate Broker and Loan Originator Have Teamed Up to Offer a One-Stop Shop!";
      } else {
        additionalText = "For a Hassle-Free Homebuying Experience!<br/>I have adopted cutting-edge Ai to offer you real estate and home financing in an easy, caring, and trusting 24/7 experience.";
      }
    } else {
      additionalText = "Your Trusted Real Estate Agent<br/>For a Hassle-free Home Buying and Selling!";
    }
  }
  
  function scrollToSection1() {
    const isDesktop = window.matchMedia("(min-width: 1024px)").matches;
    const navbarHeight = document.querySelector("nav").offsetHeight;
    const offset = 20;
    const sectionPosition =
      document.getElementById("contactusform").offsetTop -
      (isDesktop ? navbarHeight + offset : 0);
    window.scrollTo({
      top: sectionPosition,
      behavior: "smooth",
    });
  }

  const [isExpanded, setIsExpanded] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const bioRef = useRef(null);

  useEffect(() => {
    if (bioRef.current) {
      if (bioRef.current.clientHeight > 340) {
        setShowReadMore(true);
      }
    }
  }, []);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
    if (isExpanded) {
      bioRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // Generate a skeleton UI for contact details - include proper sizing to prevent layout shifts
  const ContactDetailsSkeleton = () => (
    <div className="contact-details-skeleton namejo2" style={{ minHeight: '150px' }}>
      <style>
        {`
          @keyframes shimmer {
            0% {
              background-position: -468px 0;
            }
            100% {
              background-position: 468px 0;
            }
          }
          .skeleton-animated {
            background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
            background-size: 800px 104px;
            animation: shimmer 1.5s linear infinite;
          }
          @media (max-width: 767px) {
            .contact-details-skeleton {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
            }
            .skeleton-line {
              margin-left: auto;
              margin-right: auto;
            }
          }
        `}
      </style>
      <div className="skeleton-line skeleton-animated" style={{ width: '60%', height: '14px', marginBottom: '8px', borderRadius: '4px' }}></div>
      <div className="skeleton-line skeleton-animated" style={{ width: '80%', height: '14px', marginBottom: '8px', borderRadius: '4px' }}></div>
      <div className="skeleton-line skeleton-animated" style={{ width: '70%', height: '14px', marginBottom: '8px', borderRadius: '4px' }}></div>
      <div className="skeleton-line skeleton-animated" style={{ width: '90%', height: '14px', marginBottom: '8px', borderRadius: '4px' }}></div>
      <div className="skeleton-line skeleton-animated" style={{ width: '65%', height: '14px', borderRadius: '4px' }}></div>
    </div>
  );
  
  // Actual contact details component
  const ContactDetails = () => (
    <div className="namejo2">
      {realtorData.license ? (
        <>
          License# {realtorData.license}
          <br></br>
        </>
      ) : (
        ""
      )}
      {realtorData.designation ? (
        <>
          {parser(realtorData.designation)}
          <br></br>
        </>
      ) : (
        ""
      )}
      {realtorData.mobilePhoneNumber ? (
        <>
          <FaMobile style={iconStyle} />
          <a
            style={{ textDecoration: "none" }}
            href={"tel:" + mobilePhoneNumberWithInternationalCode}
            title={mobilePhoneNumberInTitle}
            aria-label={mobilePhoneNumberInTitle}
          >
            {mobilePhoneNumberFormatted}
          </a>
          <br></br>
        </>
      ) : (
        ""
      )}
      {realtorData.workPhoneNumber ? (
        <>
          <FaPhone style={iconStyle} />
          <a
            style={{ textDecoration: "none" }}
            href={"tel:" + workPhoneNumberWithInternationalCode}
            title={workPhoneNumberInTitle}
            aria-label={workPhoneNumberInTitle}
          >
            {workPhoneNumberFormatted}
          </a>
          <br></br>
        </>
      ) : (
        ""
      )}
      {realtorData.emailAddress ? (
        <>
          <FaEnvelope style={iconStyle} />
          <a
            href={"mailto:" + realtorData.emailAddress}
            title={"Email me at " + realtorData.emailAddress}
            aria-label={"Email me at " + realtorData.emailAddress}
            className="text-decoration-none text-break"
          >
            {realtorData.emailAddress}
          </a>
          <br></br>
        </>
      ) : (
        ""
      )}
      {realtorData.userWebsite ? (
        <>
          <FaGlobe style={iconStyle} />
          <a
            href={realtorData.userWebsite}
            target="_blank"
            className="text-decoration-none text-break"
            rel="noreferrer"
          >
            {realtorData.userWebsite}
          </a>
          <br></br>
        </>
      ) : (
        ""
      )}
      {realtorData.languages ? (
        <>
          <FaLanguage style={iconStyle} />
          {realtorData.languages}
          <br></br>
        </>
      ) : (
        ""
      )}
      {realtorData.streetAddress &&
      realtorData.cityStateZip &&
      realtorData.streetAddress.trim() !== "" &&
      realtorData.cityStateZip.trim() !== "" ? (
        <>
          <FaMapMarkerAlt style={iconStyle} />
          {realtorData.streetAddress} {realtorData.cityStateZip}
        </>
      ) : (
        ""
      )}
    </div>
  );

  // Function to ensure we have meaningful content to be the LCP element
  const enhanceLcpCandidate = (element) => {
    if (element) {
      // Add data attribute to help identify potential LCP elements in analysis
      element.setAttribute('data-lcp-candidate', 'true');
      
      // Ensure this element loads quickly and is significant enough to be the LCP
      element.style.fontDisplay = 'swap';
    }
  };
  
  // Reference for potential LCP element
  const lcpHeadingRef = useRef(null);
  
  // Set up heading as LCP candidate 
  useEffect(() => {
    if (lcpHeadingRef.current) {
      enhanceLcpCandidate(lcpHeadingRef.current);
    }
  }, []);
  

  return (
    <>
      <div className={realtorName ? "banner" : "homepagebanner"}>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={9}>
              {/* Add LCP fallback element to ensure we have a fast LCP option */}
              <LcpFallbackElement />
              
              {realtorName ? (
                <div className="realtorNameTop ps-2 mb-3">
                  {/* Make this heading an LCP candidate with ref */}
                  <h1 className="realtorName" ref={lcpHeadingRef} style={{ fontDisplay: 'swap' }}>{realtorName}</h1>
                  <p className="subname">{subName}</p>
                </div>
              ) : (
                <div className="realtorNameTop ps-2 realtorHeadingSpacing">
                  <h1 className="realtorHeadingText" ref={lcpHeadingRef} style={{ fontDisplay: 'swap' }}>
                    Be A Part of the Ai Revolution
                  </h1>
                </div>
              )}
            </Col>
            <Col sm={12} md={12} lg={7}>
              <div className="bannertextdesktop ps-2">
                {realtorName ? (
                  <>
                    <h2 className="realtor-heading">
                      {parser(additionalText)}
                    </h2>
                  </>
                ) : (
                  <>
                    <div className="realtorHeadingSpacing">
                      <h2 className="realtorHeadingText1">
                        The Rise of Ai: Use It or Lose It
                      </h2>
                      <h2 className="realtorHeadingText1">
                        No Tough Loans. Close Fast. Earn More.
                      </h2>
                    </div>
                  </>
                )}
              </div>
              {!realtorData ? (
                <div className="realtorHeading3 realtorHeadingSpacing">
                  <h2 className="realtorHeadingText1 mt-lg-5">
                    Embrace a Dynamic Workflow Transformation.
                  </h2>
                  <h2 className="realtorHeadingText1 mt-lg-4 bottom-pad ">
                    Unleash the Potential of Cutting-Edge Technology.
                  </h2>
                </div>
              ) : (
                ""
              )}
              <div>
                <Image
                 src={mobileFamilyImage}
                 srcSet={`${mobileFamilyImage} 375w, ${mobileFamilyImage} 750w, ${mobileFamilyImage} 1125w`}
                 className="mobilefamily img-fluid"
                 alt="mobilefamily"
                 loading="eager"
                 width="375"
                 height="481"
                 sizes="(max-width: 768px) 375px, (max-width: 1200px) 750px, 1125px"
               />
              </div>
              <div>
                <Image
                  src={dotmatrix2}
                  className="dotmatrix2 img-fluid"
                  alt="dotmatrix2"
                  loading="lazy"
                  width="400"
                  height="100"
                />
              </div>
              <div>
                <Image
                  src={dotmatrix3}
                  className="dotmatrix3 img-fluid"
                  alt="dotmatrix3"
                  loading="lazy"
                  width="400"
                  height="100"
                />
              </div>
            </Col>
            {!realtorData ? (
              <Col sm={12} md={12} xl={5} lg={5} className='mt-lg-5'>
                <Image
                  src={bannerImage}
                  className="bannerimage img-fluid"
                  alt="Banner Image"
                  loading="eager"
                  width="638" 
                  height="713"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 638px"
                />
              </Col>
            ) : null}
          </Row>
          {realtorData ? (
            <Row className="joaquinrow">
              <Col sm={12} md={12} xl={3} lg={3}>
                <div className="joaquinsec">
                  {/* Conditionally render profile image only after delay */}
                  {profileImageLoaded ? (
                    <Image
                      src={realtorData.profileImage}
                      alt={realtorName}
                      width="200"
                      height="200"
                      className="edvin img-fluid"
                      decoding="async"
                    />
                  ) : (
                    <div 
                      className="profile-placeholder pulse-animation" 
                      style={{ 
                        width: "200px", 
                        height: "200px", 
                        background: "linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)",
                        backgroundSize: "200% 100%",
                        borderRadius: "5px",
                        margin: "0 auto",
                        animation: "1.5s shine linear infinite"
                      }}
                      aria-label="Loading profile image"
                    >
                      <style>
                        {`
                          @keyframes shine {
                            to {
                              background-position-x: -200%;
                            }
                          }
                        `}
                      </style>
                    </div>
                  )}
                  <p className="namejoaqu mt-2 mb-2">{realtorName}</p>
                  
                  {/* Delayed loading of contact details */}
                  {detailsLoaded ? <ContactDetails /> : <ContactDetailsSkeleton />}
                </div>
              </Col>
              <Col sm={12} md={12} xl={4} lg={4}>
                <div className="closebuttnmobile">
                  <Button onClick={scrollToSection1} className="contactbutton">
                    Contact Us
                  </Button>
                </div>
                <div className="document-btn">
                  {realtorData.realtorCN1 ? (
                    <a
                      href={imgCDNURL + realtorData.realtorCN1}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={imgCDNURL + "CN15button.webp"}
                        className="CN15Btn"
                        alt="CN15 Button"
                        width="120"
                        height="40"
                      />
                    </a>
                  ) : null}
                  {realtorData.realtorIABS ? (
                    <a
                      href={imgCDNURL + realtorData.realtorIABS}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={imgCDNURL + "IABSbutton.webp"}
                        className="IABSBtn"
                        alt="IABS Button"
                        width="120"
                        height="40"
                      />
                    </a>
                  ) : null}
                </div>
                <div className="introtext">
                  <div>
                    {realtorData.realtorLogo ? (
                      <Image
                        src={realtorData.realtorLogo}
                        className="img-fluid realtorlogo"
                        alt="realtorLogo"
                        width="200"
                        height="100"
                        loading="eager"
                      />
                    ) : null}
                  </div>
                  <p
                    ref={bioRef}
                    style={{
                      maxHeight: isExpanded ? "none" : "350px",
                      overflow: "hidden",
                      fontSize: "15px"
                    }}
                  >
                    {realtorData.bio ? (
                      <>{parser(realtorData.bio)}</>
                    ) : (
                      <>
                        Hello, my name is {realtorName} and I am an experienced
                        Real Estate agent. Real Estate is my passion and I have
                        helped clients to purchase or sell their home. I take
                        pride in being a dedicated and experienced professional
                        committed to providing exceptional service to my
                        clients. With my philosophy of 100% customer
                        satisfaction, I make sure to thoroughly assess my
                        client's wants and needs to help them achieve their
                        affordability and goals.
                      </>
                    )}
                  </p>
                  {showReadMore && (
                    <button onClick={toggleExpand} className="read-more-btn">
                      {isExpanded ? "Read Less" : "Read More"}
                    </button>
                  )}
                  <div className="closebuttn btn-flex">
                    <Button
                      onClick={scrollToSection1}
                      className="contactbutton"
                    >
                      Contact Us
                    </Button>
                    {realtorData.realtorCN1 ? (
                      <a
                        href={imgCDNURL + realtorData.realtorCN1}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={imgCDNURL + "CN15button.webp"}
                          className="CN15Btn"
                          alt="CN15 Button"
                          width="120"
                          height="40"
                        />
                      </a>
                    ) : null}
                    {realtorData.realtorIABS ? (
                      <a
                        href={imgCDNURL + realtorData.realtorIABS}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={imgCDNURL + "IABSbutton.webp"}
                          className="IABSBtn"
                          alt="IABS Button"
                          width="120"
                          height="40"
                        />
                      </a>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} xl={5} lg={5}>
                <Image 
                  src={bannerImage} 
                  className="bannerimage" 
                  alt="Banner illustration" 
                  loading="eager" 
                  width="638" 
                  height="713"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 638px"
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Container>
      </div>

      <div className="mortgageofficer">
        <h3 className="mb-0 mortgageOfficerText">
          {realtorData ? (
            <>
              {loanOfficerActive ? (
                <>
                  As a Real Estate Agent working with{" "}
                  {capitalizeFirstLetter(loanOfficerData.name)}{" "}
                  {loanOfficerData.nmls ? (
                    <>(NMLS {loanOfficerData.nmls})</>
                  ) : (
                    ""
                  )}
                  , I can offer many benefits to my clients!
                </>
              ) : (
                <>
                  As a Real Estate Agent working, I can offer
                  many benefits to my clients!
                </>
              )}
            </>
          ) : (
            <>
              As a Real Estate Agent you will be working with {angelAiName},
              that can offer many benefits to your clients!
            </>
          )}
        </h3>
      </div>
    </>
  );
}
)

export default Body;